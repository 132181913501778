<template>
  <v-container>
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-form
          lazy-validation
          autocomplete="new-password"
        >
          <v-text-field
            v-model="user.nome"
            autocomplete="new-password"
            label="Nome"
            name="name"
            type="text"
            :rules="nameRules"
          />

          <v-text-field
            v-model="user.email"
            autocomplete="new-password"
            label="E-mail"
            name="email"
            type="text"
            :rules="emailRules"
          />

          <v-text-field
            id="codigoStyllus"
            v-model="user.codigoStyllus"
            autocomplete="new-password"
            label="Código Styllus"
            name="codigoStyllus"
            type="codigoStyllus"
          />

          <v-text-field
            id="password"
            v-model="user.senha"
            autocomplete="new-password"
            label="Senha"
            name="password"
            type="password"
          />

          <v-select
            v-model="user.perfilId"
            label="Perfil"
            :items="profiles"
            item-text="nome"
            item-value="perfilId"
          />

          <v-btn
            color="primary"
            @click="submit"
          >
            Salvar
          </v-btn>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    data: () => ({
      user: {},
      profiles: [],
      nameRules: [
        v => !!v || 'Nome é obrigatório',
      ],
      passwordRules: [
        v => !!v || 'Senha é obrigatório',
      ],
      emailRules: [
        v => !!v || 'E-mail é obrigatório',
        v => /.+@.+\..+/.test(v) || 'E-mail precisa ser válido',
      ],
    }),
    mounted () {
      if (!this.user) {
        this.user = {}
      }
      if (this.$route.params.id) {
        this.$http.get(`/usuarios/${this.$route.params.id}`).then(resp => {
          resp.data.senha = ''
          this.user = resp.data
        })
      }
      this.$http.get('/perfis')
        .then(resp => {
          this.profiles = resp.data.rows
        })
    },
    methods: {
      submit () {
        if (!this.user.codigoStyllus) {
          delete this.user.codigoStyllus
        } else {
          this.user.codigoStyllus = parseInt(this.user.codigoStyllus)
        }

        const request = this.user.id
          ? this.$http.put('/usuarios', this.user)
          : this.$http.post('/usuarios', this.user)

        request.then(resp => {
          this.$toast.success('Operação realizada com sucesso')
          this.$router.push('/pages/usuarios')
        })
      },
    },
  }
</script>

<style>

</style>
